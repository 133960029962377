<template>
  <div class="home mb-5" v-if="status==1">
    <div class="form-html">
      <p class="row"><strong>Vielen Dank für Ihre Anfrage!</strong></p>
      <p class="row">Wir werden Sie schnellstmöglich bearbeiten.</p>
    </div>
    <div>
      <div class="form-html">
        <div class="row mb-2">
          <div class="col-12">
            <button type="submit" @click="status=0" class="btn btn-secondary">Neue Anfrage</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home mb-5" v-else-if="status==2">
    <div class="form-html">
      <p class="row"><strong>Irgendwas lief schief... bitte versuchen Sie es erneut</strong></p>
    </div>
    <div>
      <div class="form-html">
        <div class="row mb-2">
          <div class="col-12">
            <button type="submit" @click="status=0" class="btn btn-secondary">Neue Anfrage</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home mb-5" v-else>
    
    <div class="form-html">
      <p class="row">Wenn Sie Frage, oder Anregungen haben können Sie sich hier per Email an uns wenden.</p>
      <p class="row">Nat&uuml;rlich stehen wir Ihnen auch mit einer pers&ouml;nlichen Beratung unter 06034/ 906872 zur Verf&uuml;gung.</p>
      <p class="row">Bitte w&auml;hlen Sie entsprechend Ihrer Anfrage unten den Betreff:</p>
      <ul class="row-list">
        <li><strong>Generell</strong> : Wenn Sie generelle Fragen zu unseren Dienstleistungen und/oder Produkten haben </li>
        <li><strong>ich m&ouml;chte ein Logo o. Motive sticken lassen</strong> : Wenn Sie ein eigenes Motiv haben, dass Sie bei uns sticken lassen m&ouml;chten </li>
        <li><strong>ich ben&ouml;tige ein Stickprogramm</strong> : Wenn Sie ein Logo, oder Bild haben, zu welchem Sie ein Stickprogramm ben&ouml;tigen </li>
      </ul>
      <p class="row">Dabei haben Sie dann auch die M&ouml;glichkeit Ihr Bild; Logo und/oder Ihre eigene Stickdatei anzuh&auml;ngen. </p>
    </div>

    <div class="header">
      <div class="header-text">
        Kontaktdaten
      </div>
    </div>
    <form ref="form" id="form" :class="{'was-validated': formValidation}" @submit.prevent="submit" novalidate>
    <div class="form-html">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="firma" placeholder="name@example.com" />
            <label for="floatingInput">Firma</label>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="anrede" placeholder="name@example.com" />
            <label for="floatingInput">Anrede</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="vorname" placeholder="name@example.com" required @input="validateField"/>
            <label for="floatingInput">Vorname *</label>
            <div class="invalid-feedback">
              Bitte geben Sie Ihren Vornamen ein
            </div>

          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="nachname" placeholder="name@example.com" required @input="validateField"/>
            <label for="floatingInput">Nachname *</label>
            <div class="invalid-feedback">
              Bitte geben Sie Ihren Nachnamen ein
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="strasse" placeholder="name@example.com" />
            <label for="floatingInput">Straße + Hausnummer</label>
            <div class="invalid-feedback">
              Bitte geben Sie Ihre Anschrift ein
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="adress_zusatz" placeholder="name@example.com" />
            <label for="floatingInput">Adresszusatz</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="plz" placeholder="name@example.com" />
            <label for="floatingInput">PLZ</label>
            <div class="invalid-feedback">
              Bitte geben Sie Ihre Postleitzahl ein
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="ort" placeholder="name@example.com" />
            <label for="floatingInput">Ort</label>
            <div class="invalid-feedback">
              Bitte geben Sie Ihren Wohnort ein
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="land" placeholder="name@example.com" disabled required/>
            <label for="floatingInput">Land <span class="small">(wir liefern derzeit nur innerhalb Deutschlands)</span></label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">

          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" v-model="email" placeholder="name@example.com" required @input="validateField($event), validateEmail()"/>
            <label for="floatingInput">Email-Adresse *</label>
            <div class="invalid-feedback">
              Bitte geben Sie Ihre Email-Adresse ein
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="checkEmail" ref="checkEmail" v-model="email_confirm" placeholder="name@example.com"  @input="validateEmail"/>
            <label for="checkEmail">Email-Adresse best&auml;tigen *</label>
            <div class="invalid-feedback">
              Die Email-Adressen stimmen nicht überein
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" v-model="telefon" placeholder="name@example.com" />
            <label for="floatingInput">Telefonnummer</label>
          </div>
        </div>
      </div>
    </div>
    
    <div class="header">
      <div class="header-text">
        Anfrage
      </div>
    </div>

    <div class="form-html">
      <div class="row mb-3">
        <div class="col-3 fw-bold">
          Betreff: *
        </div>
        <div class="col-9">
          <select id="type_import" class="form-select " aria-label="Default select example" v-model="betreff" required @input="validateField">
              <option value="generell">Anfrage: Generell</option>
              <option value="logo">Anfrage: ich möchte ein Logo o. Motiv sticken lassen</option>
              <option value="datei">Anfrage: ich benötige eine Stickdatei</option>
          </select>
          <div class="invalid-feedback">
              Bitte wählen Sie einen Betreff aus
            </div>
        </div>
        <div class="col-6">

        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 fw-bold">
          Nachricht: *
        </div>
      </div>
      <div class="row mb-2">
        <div clss="col-12">
          <textarea rows="10" cols="45" v-model="nachricht" placeholder="Bitte geben Sie hier Ihren Nachrichtentext ein" required @input="validateField"></textarea>
          <div class="invalid-feedback">
              Bitte geben Sie Ihre Nachricht ein
            </div>
        </div>
      </div>
    </div>

    <div v-if="betreff === 'logo'">
      <div class="header">
        <div class="header-text">
          Angaben zum Stickmuster
        </div>
      </div>
      <div class="form-html">
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Maße (HxB cm): *
          </div>
          <div class="col-9">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" v-model="masse" placeholder="name@example.com" required @input="validateField"/>
              <label for="floatingInput">Höhe x Breite in cm</label>
              <div class="invalid-feedback">
              Bitte geben Sie die Maße ein
            </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Copyright: *
          </div>
          <div class="col-9">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" name="copyright" id="copyright" v-model="copyright" required @input="validateField">
              <label class="form-check-label" for="copyright">
                  Ich/ Wir bestätigen, dass ich/ wir das Copyright, bzw. die Berechtigung zur Nutzung für das zur Stickmustererstellung vorgelegte Design (Logos, Bilder, Schriften, etc.) besitzen.
              </label>
              <div class="invalid-feedback">
              Bitte bestätigen Sie das Copyright
            </div>
          </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Datei: *
          </div>
          <div class="col-9">
              <input ref="fileInput" type="file" @change="handleFileChange1" accept=".pdf,.zip,.jpg,.jpeg,.bmp,.png,.gif,.eps,.cpt,.cdr,.dst,.tiff" required @input="validateFile">
              
              <input ref="fileInput" type="file" @change="handleFileChange2" accept=".pdf,.zip,.jpg,.jpeg,.bmp,.png,.gif,.eps,.cpt,.cdr,.dst,.tiff">
              <input ref="fileInput" type="file" @change="handleFileChange3" accept=".pdf,.zip,.jpg,.jpeg,.bmp,.png,.gif,.eps,.cpt,.cdr,.dst,.tiff">
              <div class="invalid-feedback">
                Bitte wählen Sie ein bis drei Dateien aus
              </div>
              <div v-if="fileErrors.length" class="text-danger">
                <ul>
                  <li v-for="error in fileErrors" v-bind:key="error">{{ error }}</li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="betreff === 'datei'">
      <div class="header">
        <div class="header-text">
          Angaben zum Stickmuster
        </div>
      </div>
      <div class="form-html">
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Mustername: *
          </div>
          <div class="col-9">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" v-model="muster_name" placeholder="name@example.com" required @input="validateField"/>
              <label for="floatingInput">Bitte Mustername eingeben</label>
              <div class="invalid-feedback">
              Bitte geben Sie einen Musternmen ein
            </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Maße (HxB cm): *
          </div>
          <div class="col-9">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" v-model="masse" placeholder="name@example.com" required @input="validateField"/>
              <label for="floatingInput">H&ouml;he x Breite in cm</label>
              <div class="invalid-feedback">
              Bitte geben Sie die Maße ein
            </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Garnst&auml;rke: *
          </div>
          <div class="col-9">
            <fieldset>
              <div class="row">
                <div class="col-6">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="garn" id="40" value="40er" v-model="garn" @input="validateGarnstaerke">
                    <label class="form-check-label" for="40">
                      40er = standard
                    </label>
                    <div class="invalid-feedback">
                    Bitte wählen Sie eine Garnst&auml;rke aus
                  </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="garn" id="30" value="30er" v-model="garn" @input="validateGarnstaerke">
                    <label class="form-check-label" for="30">
                      30er
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="garn" id="60" value="60er" v-model="garn" @input="validateGarnstaerke">
                    <label class="form-check-label" for="60">
                      60er
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="garn" id="metall" value="Metallgarn" v-model="garn" @input="validateGarnstaerke">
                    <label class="form-check-label" for="metall">
                      Metallgarn
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Anzahl Farben/Nadeln: *
          </div>
          <div class="col-9">
            <div class="mb-3">
              <input type="number" min="1" max="20" class="form-control" id="floatingInput" v-model="anz_farben" placeholder="name@example.com" />
              
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Stoffart: * 
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="polo" v-model="stoffe" value="Polo" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe">
                  <label class="form-check-label" for="polo">
                    Polo
                  </label>
                  <div class="invalid-feedback">
                    Bitte wählen Sie eine Stoffart aus
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="fleece" v-model="stoffe" value="Fleece" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe"> 
                  <label class="form-check-label" for="fleece">
                    Fleece
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="kappen" v-model="stoffe" value="Kappen" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe">
                  <label class="form-check-label" for="kappen">
                    Kappen / Caps
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="softshell" v-model="stoffe" value="Softshell" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe">
                  <label class="form-check-label" for="softshell">
                    Stoftshell
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="hemd" v-model="stoffe" value="Hemd" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe">
                  <label class="form-check-label" for="hemd">
                    T-Shirt / Hemd
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="leder" v-model="stoffe" value="Leder" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe">
                  <label class="form-check-label" for="leder">
                    Leder
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="wolle" v-model="stoffe" value="Wolle" :required="stoffe.length == 0 && !stoff_other" @input="validateStoffe">
                  <label class="form-check-label" for="wolle">
                    Wolle
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="stoffart" id="andere" v-model="stoff_other" :required="stoffe.length == 0" @input="validateStoffe">
                  <label class="form-check-label" for="andere">
                    andere Stoffart (bitte unten eingeben) {{ stoff_other }}
                  </label>
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="row mb-2" v-if="stoff_other">
          <div class="col-3 fw-bold">
            andere Stoffart: *
          </div>
          <div class="col-9">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" v-model="custom_stoff" placeholder="name@example.com" required @input="validateField"/>
              <label for="floatingInput">andere Stoffart</label>
              <div class="invalid-feedback">
              Bitte geben Sie eine Stoffart ein
            </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Grundstofffarbe: *
          </div>
          <div class="col-9">
            <select id="type_import" class="form-select " aria-label="Default select example" v-model="stofffarbe" required @input="validateField">
              <option selected value="">Bitte ausw&auml;hlen</option>
              <option value="hell">Hell</option>
              <option value="dunkel">Dunkel</option>
              <option value="hell_dunkel">Hell und Dunkel</option>
            </select>
            <div class="invalid-feedback">
              Bitte wählen sie eine Grundstofffarbe
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Maschinenformat: *
          </div>
          <div class="col-9">
            <select id="type_import" class="form-select" aria-label="Default select example" v-model="format" required @input="validateField">
              <option selected value="">Bitte ausw&auml;hlen</option>
              <option value="dst">DST (Taijmna)</option>
              <option value="dsz">DSZ (ZSK)</option>
              <option value="pes">PES (Brother / Bernina)</option>
              <option value="tap">TAP (Happy)</option>
              <option value="ksm">KSM (Pfaff)</option>
              <option value="other">anderes Format</option>
            </select>
            <div class="invalid-feedback">
              Bitte wählen Sie ein Maschinenformat
            </div>
          </div>
        </div>
        <div class="row mb-2" v-if="format === 'other'">
          <div class="col-3 fw-bold">
            anderes Maschinenformat: *
          </div>
          <div class="col-9">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" v-model="custom_format" placeholder="name@example.com" required @input="validateField"/>
              <label for="floatingInput">bitte Format eingeben</label>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Copyright: *
          </div>
          <div class="col-9">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" name="copyright" id="copyright" v-model="copyright" required @input="validateField">
              <label class="form-check-label" for="copyright">
                  Ich/ Wir bestätigen, dass ich/ wir das Copyright, bzw. die Berechtigung zur Nutzung für das zur Stickmustererstellung vorgelegte Design (Logos, Bilder, Schriften, etc.) besitzen.
              </label>
              <div class="invalid-feedback">
                Bitte bestätigen Sie das Copyright
            </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 fw-bold">
            Datei: *
          </div>
          <div class="col-9">
              <input ref="fileInput" type="file" @change="handleFileChange1" accept=".pdf,.zip,.jpg,.jpeg,.bmp,.png,.gif,.eps,.cpt,.cdr,.dst,.tiff" required @input="validateFile">
              <input ref="fileInput" type="file" @change="handleFileChange2" accept=".pdf,.zip,.jpg,.jpeg,.bmp,.png,.gif,.eps,.cpt,.cdr,.dst,.tiff" >
              <input ref="fileInput" type="file" @change="handleFileChange3" accept=".pdf,.zip,.jpg,.jpeg,.bmp,.png,.gif,.eps,.cpt,.cdr,.dst,.tiff" >
              <div class="invalid-feedback">
                Bitte wählen Sie ein bis drei Dateien aus
              </div>
              <div>
                <p class="small">max. 3MB je Datei - nur Bilddateien, wie jpeg, bmp und ZIP<br>
                Falls Sie ein bestehendes Stickmuster schon vorliegend haben, laden Sie bitte hier auch ein Foto mit hoch.<br>
                  Jeder Puncher interpretiert die Motive anders. Aber wenn der Kunde schon ein Motiv gesehen hat, möchte er es auch wieder sehen</p></div>
              <div v-if="fileErrors.length" class="text-danger">
                <ul>
                  <li v-for="error in fileErrors" v-bind:key="error">{{ error }}</li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="form-html">
        <div class="row mb-2">
          <div class="col-12">
            <p> Bitte beachten Sie unsere <a title="Datenschutzerklärung" href="https://www.sannes-stickdesign.de/privatsphaere-und-datenschutz" target="_blank" rel="nofollow">Datenschutzerklärung</a></p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="form-html">
        <div class="row mb-2">
          <div class="col-12">
            <button type="submit" class="btn btn-secondary" :disabled="backend_working">Senden</button>
            <button type="button" class="btn btn-secondary" @click="resetForm" :disabled="backend_working">Formular leeren</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Honeypot field 1: a hidden field that spambots might populate -->
    <label for="honeypot1" style="display: none;">Do not fill in this field:</label>
    <input type="text" id="honeypot1" name="honeypot1" style="display: none;" v-model="honey1"/><br />

    <!-- Honeypot field 2: a field that is offscreen and spambots might populate -->
    <div style="position: absolute; left: -1000px;">
        <label for="honeypot2">Do not fill in this field:</label>
        <input type="text" id="honeypot2" name="honeypot2" v-model="honey2" /><br />
    </div>
  </form>
  
  </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { ModelSelect } from "vue-search-select"
import axios from 'axios'
import qs from 'qs';
export default {
  name: 'Home',
  components: {
        ModelSelect,
    },
    mounted() {
      const betreff_query = this.$route.query.betreff;

      if(1 == betreff_query){
        this.betreff = 'generell'
      }else if (2 == betreff_query){
        this.betreff = 'logo'
      }else if (3 == betreff_query){
        this.betreff = 'datei'
      }

    },
  data() {
    return {
      honey1: '',
      honey2: '',
      betreff: '',
      firma: '',
      anrede: '',
      vorname: '',
      nachname: '',
      strasse: '',      
      adress_zusatz: '',    
      plz: '',
      ort: '',
      land: 'Deutschland',
      email: '',
      email_confirm: '',
      telefon: '',
      nachricht:'',
      masse: '',
      copyright: false,
      file_blob: '',
      muster_name: '',
      anz_farben: 1,
      stofffarbe: '',
      format: '',
      custom_format: '',
      garn: [],
      stoffe: [],
      stoff_other: false,
      custom_stoff: '',
      backend_working: false,
      selectedFiles: [],
      fileErrors: [],
      formValidation: false,
      stoffartError:'',
      garnstaerkeError:'',
      status: 0

    }
  },
  methods:{
    validateFile(event){
      
      console.log('entry validateFile')
      const element = event.target

      element.classList.remove('is-invalid');
      element.classList.add('is-valid');

      console.log('exit validateFile')
    },
    validateGarnstaerke(){
      console.log('entry validateGarnstaerke')
      var checkboxes = document.getElementsByName("garn");
      var isChecked = false;
      console.log('checkboxes: ', checkboxes)
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            isChecked = true;
            break;
        }
      }
      if(!isChecked){
        this.garnstaerkeError = 'Bitte wählen Sie eine Garnstaerke aus'
        checkboxes.forEach(checkbox =>{
          checkbox.classList.add('is-invalid');
          checkbox.classList.remove('is-valid');
        })
      }else{
        this.garnstaerkeError = ''
        checkboxes.forEach(checkbox =>{
          checkbox.classList.add('is-valid');
          checkbox.classList.remove('is-invalid');
        })
      }
      console.log('exit validateGarnstaerke')
    },
    validateStoffe(){
      console.log('entry validateStoffe')
      var checkboxes = document.getElementsByName("stoffart");
      var isChecked = false;
      console.log('checkboxes: ', checkboxes)
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            isChecked = true;
            break;
        }
      }
      if(!isChecked){
        this.stoffartError = 'Bitte wählen Sie eine Stoffart aus'
        checkboxes.forEach(checkbox =>{
          checkbox.classList.add('is-invalid');
          checkbox.classList.remove('is-valid');
        })
      }else{
        this.stoffartError = ''
        checkboxes.forEach(checkbox =>{
          checkbox.classList.add('is-valid');
          checkbox.classList.remove('is-invalid');
        })
      }
      console.log('exit validateStoffe')
    },
    validateEmail(){
      console.log('entry validateEmail')
      const element = this.$refs.checkEmail
      if (this.email != this.email_confirm){
        element.classList.add('is-invalid');
        
      }else{
        element.classList.remove('is-invalid');
          element.classList.add('is-valid');
      }
      console.log('exit validateEmail')
    },
    validateField(event){
      console.log('entry validateField')
      const element = event.target
      console.log(element)
      if (!this.$refs.form.checkValidity()) {
        //this.formValidation =true
        var allInvalidFields = this.$refs.form.querySelectorAll(':invalid')
        console.log('allInvalid:')
        console.log(allInvalidFields)
        if(Array.from(allInvalidFields).includes(element)){
          element.classList.add('is-invalid');
        }else{
          element.classList.remove('is-invalid');
          element.classList.add('is-valid');
        }
      }
      console.log('exit validateField')
    },
    handleFileChange1(event) {
      const selectedFiles = event.target.files[0];

      // Zurücksetzen der Fehlermeldungen
      this.fileErrors = [];

      // Überprüfen, ob 1 bis 3 Dateien ausgewählt wurden
      if (selectedFiles.length < 1 || selectedFiles.length > 3) {
        this.fileErrors.push('Bitte wählen Sie 1 bis 3 Dateien aus.');
      }

      // Überprüfen der Dateigröße
      const maxSize = 3 * 1024 * 1024; // 3 MB in Bytes
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > maxSize) {
          this.fileErrors.push(`Die Datei ${file.name} ist zu groß (Max. 3 MB erlaubt).`);
        }
      }

      this.selectedFiles.push(selectedFiles);
    },
    handleFileChange2(event) {
      const selectedFiles = event.target.files[0];

      // Zurücksetzen der Fehlermeldungen
      this.fileErrors = [];

      // Überprüfen, ob 1 bis 3 Dateien ausgewählt wurden
      if (selectedFiles.length < 1 || selectedFiles.length > 3) {
        this.fileErrors.push('Bitte wählen Sie 1 bis 3 Dateien aus.');
      }

      // Überprüfen der Dateigröße
      const maxSize = 3 * 1024 * 1024; // 3 MB in Bytes
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > maxSize) {
          this.fileErrors.push(`Die Datei ${file.name} ist zu groß (Max. 3 MB erlaubt).`);
        }
      }

      this.selectedFiles.push(selectedFiles);
    },
    handleFileChange3(event) {
      const selectedFiles = event.target.files[0];

      // Zurücksetzen der Fehlermeldungen
      this.fileErrors = [];

      // Überprüfen, ob 1 bis 3 Dateien ausgewählt wurden
      if (selectedFiles.length < 1 || selectedFiles.length > 3) {
        this.fileErrors.push('Bitte wählen Sie 1 bis 3 Dateien aus.');
      }

      // Überprüfen der Dateigröße
      const maxSize = 3 * 1024 * 1024; // 3 MB in Bytes
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > maxSize) {
          this.fileErrors.push(`Die Datei ${file.name} ist zu groß (Max. 3 MB erlaubt).`);
        }
      }

      this.selectedFiles.push(selectedFiles);
    },
    scrollToTop(){
      window.scrollTo(0,0);
    },
    submit(){
      
      this.validateStoffe()
      if (!this.$refs.form.checkValidity()) {
        //this.formValidation =true
        var allInvalidFields = this.$refs.form.querySelectorAll(':invalid')
        console.log('allInvalid:')
        console.log(allInvalidFields)

        allInvalidFields.forEach(invalidField =>{
          console.log(invalidField)
          invalidField.className += " is-invalid"
        })
        if (this.email != this.email_confirm){
          this.$refs.form.querySelector('#checkEmail').className += " is-invalid"
         
        }
        const firstInvalidField = this.$refs.form.querySelector(':invalid');
        firstInvalidField.focus();
        return
      }

      if (this.email != this.email_confirm){
        this.$refs.checkEmail.focus()
        return
      }
      this.backend_working = true
      var formData = {
        betreff: this.betreff,
        firma: this.firma,
        anrede: this.anrede,  
        vorname: this.vorname,
        nachname: this.nachname,
        adresse: this.strasse,
        adress_zusatz: this.adress_zusatz,
        plz: this.plz,
        ort: this.ort,
        land: this.land,
        email: this.email,
        telefon: this.telefon,
        nachricht: this.nachricht,
        honeypot1: this.honey1,
        honeypot2: this.honey2
      };
      if(this.betreff==='logo'){
        formData = { ...formData,
          masse: this.masse,
          copyright_: true
        }
      }
      if(this.betreff==='datei'){
        var format = ''
        if(this.custom_format){
          format = this.custom_format
        }else{
          format = this.format
        }

        var garnstaerke = this.garn.join(', ')
        
        var stoffart = this.stoffe.join(', ')
        
        if(this.custom_stoff){
          stoffart += ', ' + this.custom_stoff
        }
        formData = { ...formData,
          muster_name: this.muster_name,
          masse: this.masse,
          garnstaerke: garnstaerke,
          stoffart: stoffart,
          grundstofffarbe: this.stofffarbe,
          maschinenformat: format,
          anzahl_farben: this.anz_farben,
          copyright_: true
        }

      }

      if(this.selectedFiles){
        if(this.selectedFiles.length == 1){
          formData = { ...formData,
          file1: this.selectedFiles[0]}
        }
        else if(this.selectedFiles.length == 2){
          formData = { ...formData,
          file1: this.selectedFiles[0],
          file2: this.selectedFiles[1]}
        }
        else if(this.selectedFiles.length == 3){
          formData = { ...formData,
          file1: this.selectedFiles[0],
          file2: this.selectedFiles[1],
          file3: this.selectedFiles[2]}
        }
      }
      console.log(formData)
      //formData = qs.stringify(formData)
      console.log('formData')
      console.log(formData)

      // Erstellen Sie einen Konfigurationsobjekt für Axios, um den Content-Type festzulegen
      const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
        }
      };
      axios
        .post(`/submit-form`, formData, config)
        .then(response => {
            createToast({text: "Anfrage erfolgreich gesendet"
            ,type: 'success'})
            this.resetForm()
            this.status = 1
        })
        .catch(error => {
          this.backend_working = false
          this.status = 2
            if (error.response) {
                if ('detail' in error.response.data){
                  console.log('typeof')
                  console.log(typeof error.response.data['detail'])
                    if(typeof error.response.data['detail'] === "object")
                    {
                      const error_obj = error.response.data['detail']
                      console.log('error_obj')
                      console.log(error_obj)
                      if(Array.isArray(error_obj)){
                        error_obj.forEach(element => {
                          console.log(element)
                          createToast({text: JSON.stringify(element)
                                  ,type: 'error'})
                        });
                      }
                      else{
                        createToast({text: JSON.stringify(error_obj)
                                  ,type: 'error'})
                        
                      }             
                    }else{
                      createToast({text: error.response.data['detail']
                                ,type: 'error'})
                    }
                    
                }                        
                console.error(JSON.stringify(error.response.data))
            } else if (error.message) {
                console.error(JSON.stringify(error.message))
            } else {
                console.error(JSON.stringify(error))
            }
        })
    },
    resetForm(){
      this.scrollToTop()
      this.formValidation = false
      this.backend_working = false
      this.betreff = ''
      this.firma= ''
      this.anrede= ''
      this.vorname= ''
      this.nachname= ''
      this.strasse= ''
      this.adress_zusatz= ''
      this.plz= ''
      this.ort= ''
      this.email= ''
      this.email_confirm= ''
      this.telefon= ''
      this.nachricht=''
      this.masse= ''
      this.copyright= false
      this.file_blob= ''
      this.muster_name= ''
      this.anz_farben= 1
      this.stofffarbe= 'dummy'
      this.format= 'dummy'
      this.custom_format= ''
      this.garn = []
      this.stoff_other= false
      this.custom_stoff= ''
      this.garnstaerkeError = ''
      this.stoffartError = ''
      this.stoffe= []
      this.selectedFiles = []
      

      const elementsToRemoveValidation = document.querySelectorAll('.is-invalid, .is-valid');

      // Iterieren Sie durch die ausgewählten Elemente und entfernen Sie die Klassen
      elementsToRemoveValidation.forEach(element => {
        element.classList.remove('is-invalid', 'is-valid');
      });
    }
  },
  
}
</script>
