<template>
  <router-view/>
  <div id="toast-container"></div>
</template>

<script>

  import axios from 'axios'
export default {
  name: 'Home',
    beforeCreate() {
      console.log("init beforeCreate")
      this.$store.commit('initializeStore')

    },
    mounted(){
    },
    methods:{
    }
}
</script>

<style lang="scss">
@import '/scss/custom.scss';
@import './assets/fontawesome/css/all.css';
</style>