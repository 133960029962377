import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import "vue-search-select/dist/VueSearchSelect.css";

axios.defaults.baseURL = process.env.VUE_APP_AXIOS

const app = createApp(App)
app.use(store)
app.use(router, axios)
app.mount('#app')

